import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Passage from "./components/Passage";
import DayChooser from "./components/DayChooser";

import "./App.scss";

function App() {
  return (
    <div className="App">
      <div className="page">
        <header className="App-header" id="page-top">
          <span className="site-title">For the Day</span>
          <span className="site-tagline">
            a daily dose of psalms and proverbs
          </span>
        </header>
        <Router>
          <Switch>
            <Route path="/day-chooser">
              <DayChooser book="psalms" />
            </Route>
            <Route path="/:book/:dayPath">
              <Passage />
            </Route>
            <Route path="/:book">
              <Passage />
            </Route>
            <Route>
              <Redirect to="/psalms" />
            </Route>
          </Switch>
        </Router>
      </div>
    </div>
  );
}

export default App;

import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import { fetchBook } from "../service/verses";
import { formatPassages } from "../helpers/formatters";

import Viewer, { Props } from "./Viewer";

type Content = { [key: string]: object }
interface RouteParams {
  book: string,
  dayPath: string,
}

const Component: React.FC = React.memo(() => {
  // constants based on hooks
  const { book, dayPath } = useParams<RouteParams>();
  const [bookContent, setBookContent] = useState<object>(<p>Loading...</p>);

  // Other constants
  const now = moment();
  const day = dayPath ? dayPath : now.format("D");
  const dayTitle = dayPath
    ? `Showing for the ${moment(`${now.format("YYYY/MM/")}/${day}`).format(
        "Do"
      )}`
    : now.format("LL");

  // Memoized function to fetch book from api, used by getBook
  const _fetchBookFromApi = useMemo(async () => {
    const result = await fetchBook(book, day);
    setBookContent(formatPassages(result));
  }, [book, day]);

  // The main get book functino to be called
  const getBook = async () => {
    await _fetchBookFromApi;
  };

  getBook();

  const props: Props = {
    dayTitle,
    dayPath,
    book,
    bookContent
  };

  return <Viewer {...props} />;
})

export default Component;

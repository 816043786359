import React from "react";
import { Link } from "react-router-dom";

import "./DayChooser.scss";

const Component: React.FC<{book: string | null}> = ({book=null}) => {
  let links = []
  for (let i = 1; i<=31; i++) {
    links.push(
      <div key={`day-link-${i}`} className="day-chooser-cell">
        <Link className="day-chooser-link" to={`${book}/${i}`}>
          {i}
        </Link>
      </div>
    );
  }

  return (
    <main>
      <h1>Pick a new day:</h1>
      <div className="day-chooser">{links}</div>
      <p className="back-to-today-link">
        Or
        {' '}
        <Link to={"/" + book}>
          go back to today
        </Link>
      </p>
    </main>
  );
}

export default Component;

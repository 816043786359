import React from "react";
import DayLink from "./DayLink";

import "./DayTitle.scss";

export type Props = {
  dayTitle: string;
  dayPath: string;
  book: string;
}

const Component: React.FC<Props> = ({dayTitle, dayPath, book}) => {
  
  return (
    <h1 className="day-title">
      <span>{dayTitle}</span>
      <DayLink day={dayPath} book={book} />
    </h1>
  );
}

export default Component;

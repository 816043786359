import { apiBase } from "../constants/api";

const _getUri = (book: string, day: string) => `${apiBase()}${book}/${day}`;

export const fetchBook = async (book: string, day: string) => {
  let fetchUri = _getUri(book, day);
  try {
    const response = await fetch(fetchUri);
    const data = await response.json();
    return data.passages;
  } catch (error) {
    console.log(`Error fetching verses: ${error}`);
  }
}
import React from "react";

const Component: React.FC<{content: string}> = ({content}) => {
  
  return (
    <div id="content">{content}</div>
  );
}

export default Component;

import React from "react";

export const formatPassages: (passages: any) => object = (passages: [[{
  id: string;
  reference: string;
  copyright: string;
  content: string;
}]] | undefined) => {
  // Placeholder-ish function to sanitize and return html
  const processHtml: (html:string) => {__html: string} = html => {
    return { __html: html };
  };

  if (!passages) {
    return <p>No verses found.</p>;
  }

  let copyright = "";

  const passage_text = passages.map((passage: any) => {
    if (passage.length) {
      passage = passage[0];
    }
    copyright = passage.copyright;
    return (
      <div key={`passage--${passage.id}`} className="passage">
        <h2>{formatHeading(passage.reference)}</h2>
        <div dangerouslySetInnerHTML={processHtml(passage.content)} />
      </div>
    );
  });

  return (
    <>
      {passage_text}
      <p>
        <small>{copyright}</small>
      </p>
    </>
  );
};

function formatHeading(text: string) {
  if (text.indexOf(":")) {
    return text.split(":")[0]
  } else {
    return text
  }
}

import React from "react";

import DayTitle from "./DayTitle";
import Tabs from "./Tabs";
import Content from "./Content";

import "./Viewer.scss";

export type Props = {
  dayTitle: string,
  dayPath: string,
  book: string,
  bookContent: any
}

const Component: React.FC<Props> = React.memo(
  ({
    dayTitle,
    dayPath,
    book,
    bookContent
  }) => {

  return (
    <main>
      <DayTitle dayPath={dayPath} dayTitle={dayTitle} book={book} />
      <Tabs dayPath={dayPath} />
      <Content content={bookContent} />
      <p><a href="#page-top">Back to top</a></p>
    </main>
  );
});

export default Component;

import React from "react";
import { Link } from "react-router-dom";

import "./DayLink.scss";

export type Props = {
  day: string | null,
  book: string
}

const Component: React.FC<Props> = ({ day = null, book }) => {
  if (day) {
    return (
      <Link className="day-link" to={"/" + book}>
        Show today
      </Link>
    );
  } else {
    return (
      <Link className="day-link" to="/day-chooser">
        See another day
      </Link>
    );
  }
};

export default Component;

import React from "react";
import { NavLink } from "react-router-dom";
import { books } from "../constants/books";

import "./Tabs.scss";

export type Props = {
  dayPath: string | null
}

const Component: React.FC<Props> = ({ dayPath = null }) => {
  const dayPathAppend = dayPath ? "/" + dayPath : "";

  return (
    <nav className="section-nav tabs">
      {Object.keys(books).map((key) => (
        <NavLink
          key={`link-${key}`}
          className="tab-link"
          to={`/${key + dayPathAppend}`}
        >
          {books[key]}
        </NavLink>
      ))}
    </nav>
  );
};

export default Component;
